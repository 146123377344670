import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import {Button, Form, Container, Row, Col,Dropdown} from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import "./BackButton.scss"
// import { BackParentMenu } from "../../../queries/common_use_query";
import { useStaticQuery, graphql } from "gatsby"
import { isMobile } from "react-device-detect";
import { useLocation } from "@reach/router"

// markup
const BackButton = (props ) => {
    const data = useStaticQuery(graphql`
    query  {  
      glstrapi {
        allMenus(sort:"Sorting:asc") {
          id
          Label
          Primary_URL
          Secondary_URL
          Show_In_Burger
          New_Tab_Open
          Main_Parent {
            id
            Label
            Primary_URL
            Secondary_URL
            Disable_Parent_URL
            New_Tab_Open
          }
          Sub_Parent {
            id
            Label
            Primary_URL
            Secondary_URL
          }
        }
      }
    }
  `)
    var menu_data = data.glstrapi.allMenus;
    if (typeof window !== "undefined") {
        var pageURL = window.location.href.split("#")[0] ? window.location.href.split("#")[0] : '';
        var removeSlash = pageURL.replace(/^(.+?)\/*?$/, "$1");
        var currenturl = props.alias?props.alias:removeSlash.substr(removeSlash.lastIndexOf('/') + 1);
        currenturl = currenturl.replace(/\?.+/, '')
        if(currenturl === "send-to-friend" || currenturl === "book-a-viewing") {
          currenturl=""
        }
    } 
    //const {loading, error, data} = BackParentMenu(currenturl);
    const location = useLocation();
    return ( 
        <React.Fragment>
           <div className={`back-block breadcrumbs ${props.style}`}>
               <Container>
                   <Row>
                       <Col md="12">
                          {location.pathname != "/branches/" && location.pathname.includes("/branches/") && isMobile?
                          <div className="val-office-banner-head responsive-val-head d-block d-lg-none">
                            <Link to ="/property-valuation/">
                            CLICK HERE TO BOOK A <span>FREE</span> VALUATION
                            </Link>
                           </div> : "" }
                           <div className="back-wrap">
                                <Link to="/" className="back-btn">Home<i className="icon-arrow-right"></i></Link>
                               {props.guidepage ? <Link to="/branches/" className="back-btn">Contact<i className="icon-arrow-right"></i></Link> : ""}
                               
                               {props.guidepage ? <Link to="/branches/area-guides/" className="back-btn">Area Guides<i className="icon-arrow-right"></i></Link> : ""}
                               {props.guidepage ?  <Link to="" className="back-btn">{props.guideName}<i className="icon-arrow-right"></i></Link> : ""}
                              
                                {menu_data && menu_data.length > 0 && menu_data.map((menu, index) => {     
                                return<>        
                                {menu.Main_Parent === null && menu.Sub_Parent === null && menu.Primary_URL === currenturl &&
                                <Link to={`/${menu.Primary_URL}/`} className="back-btn">{menu.Label}<i className="icon-arrow-right"></i></Link>
                                }                        
                                {menu.Main_Parent && menu.Sub_Parent === null && menu.Primary_URL === currenturl &&
                                <Link to={`/${menu.Main_Parent.Primary_URL}/`} className="back-btn">{menu.Main_Parent.Label}<i className="icon-arrow-right"></i></Link>
                                }
                                {menu.Main_Parent && menu.Sub_Parent && menu.Main_Parent.Disable_Parent_URL == true && menu.Primary_URL === currenturl &&
                                <Link to={`/${menu.Sub_Parent.Primary_URL}/`} className="back-btn">{menu.Sub_Parent.Label}<i className="icon-arrow-right"></i></Link>
                                }
                                {menu.Main_Parent && menu.Sub_Parent && menu.Main_Parent.Disable_Parent_URL != true && menu.Primary_URL === currenturl && !props.isReview &&
                                <Link to={`/${menu.Main_Parent.Primary_URL}/${menu.Sub_Parent.Primary_URL}/`} className="back-btn">{menu.Sub_Parent.Label}<i className="icon-arrow-right"></i></Link>
                                }  
                                {menu.Main_Parent !=null && !props.itemlabel && !props.menulabel && menu.Primary_URL === currenturl &&
                                <Link className="back-btn">{menu.Label} <i className="icon-arrow-right"></i></Link>                           
                                }
                                {menu.Main_Parent !=null && !props.itemlabel && props.menulabel && menu.Primary_URL === currenturl &&
                                <Link to={`/${menu.Main_Parent.Primary_URL}/${menu.Primary_URL}/`} className="back-btn">{menu.Label} <i className="icon-arrow-right"></i></Link>                           
                                }
                                </>
                                })}
                                {props.baselabel && props.baseurl &&
                                <Link to={props.baseurl} className="back-btn">{props.baselabel}<i className="icon-arrow-right"></i></Link>
                                }
                                {props.itemlabel &&
                                <Link to={props.property_url?props.property_url:""} className="back-btn">{props.itemlabel}</Link>
                                }
                                {props.menulabel &&
                                <Link className="back-btn">{props.menulabel}</Link>
                                }
                           </div>
                       </Col>
                   </Row>
               </Container>
           </div>
        </React.Fragment>
    )
}
export default BackButton